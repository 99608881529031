export interface AnalyticsParam {
  name: string;
  caption: string;
  after?: string;
}

export class AnalyticsParamDto {
  name: string;
  caption: string;
  after?: string;

  constructor(options: AnalyticsParam) {
    this.name = options.name;
    this.caption = options.caption;
    this.after = options.after;
  }
}
