import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API_BASE_URL } from "../app.config";
import { CredentialsDto } from "../dto/credentials-dto";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http$: HttpClient) {}

  login(credentials: CredentialsDto): Observable<void> {
    return this.http$.post<void>(API_BASE_URL + '/login', credentials);
  }
}
