import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CustomDate } from './core/custom-date';
import { AnalyticsDto } from './dto/analytics-dto';
import { CredentialsDto } from './dto/credentials-dto';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './services/auth.service';
import * as Highcharts from 'highcharts';
import { AnalyticsParam, AnalyticsParamDto } from './dto/analytics-param-dto';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsByDay } from './dto/analytics-by-day-dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit, AfterViewInit {
  public title: string = 'analytics';
  public isUserLoggedIn: boolean = false;
  public isLoading: boolean = false;

  public beginDate: CustomDate = new CustomDate();
  public endDate: CustomDate = new CustomDate();

  public analytics: AnalyticsDto[] = [];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  analyticsParams: AnalyticsParam[] = [];
  currentParam: AnalyticsParam = null;
  analyticsByDay: AnalyticsByDay[] = [];

  isDataLoading: boolean = false;

  constructor(private authSvc$: AuthService, private svc$: AnalyticsService) {
    this.handleChangedDate = this.handleChangedDate.bind(this);

    this.Highcharts.setOptions({
      lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        rangeSelectorFrom: "С",
        rangeSelectorTo: "По",
        rangeSelectorZoom: "Период",
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график'   
      }        
    });
  }

  onAnalyticsParamChange(event: Event): void {
    const select = <HTMLSelectElement> event.target;
    const param: AnalyticsParamDto = this.analyticsParams.find(p => p.name === select.value);
    this.currentParam = param;
    this.updateChart();
  }

  toNormaTimeView(duration: number): string {
    const toTwoDigit = (num: number): string => {
      return num > 9 ? num.toString() : '0' + num;
    }

    const hours: number = Math.floor(duration / 3600);
    const days: number = Math.floor((duration - hours * 3600) / 60);
    const secs: number = duration - hours * 3600 - days * 60;

    return toTwoDigit(hours) + ':' + toTwoDigit(days) + ':' + toTwoDigit(secs);
  }

  auth(): void {
    if (this.isUserLoggedIn) return;

    let credentials: CredentialsDto = JSON.parse(localStorage.getItem("analyticsCreds"));

    if (credentials === null) {
      setTimeout(() => {
        credentials = new CredentialsDto({
          login: prompt("Введите логин:", "nologin"),
          password: prompt("Введите пароль:", "nopassword")
        });

        this.login(credentials);
      });
    } else {
      this.login(credentials);
    }
  }

  login(credentials: CredentialsDto): void {
    this.isLoading = true;

    this.authSvc$.login(credentials).subscribe(() => {
      this.isUserLoggedIn = true;
      localStorage.setItem("analyticsCreds", JSON.stringify(credentials));
      this.isLoading = false;

      setTimeout(() => {
        this.initDatePicker();
        this.getData();
      });
    }, error => {
      this.isUserLoggedIn = false;
      alert("Не удалось авторизоваться.");
      this.isLoading = false;
    });
  }

  getAnalytics(): void {
    this.isDataLoading = true;

    forkJoin({
      analytics: this.svc$.getAnalytics(this.beginDate, this.endDate),
      analyticsByDay: this.svc$.getAnalyticsByDay(this.beginDate, this.endDate)
    }).subscribe(result => {
      this.analytics = result.analytics;
      this.analyticsByDay = result.analyticsByDay;
      this.updateChart();
      this.isDataLoading = false;
    });
  }

  getParams(): void {
    this.svc$.getParams().subscribe(params => {
      this.analyticsParams = params;
    });
  }

  getData(): void {
    this.getParams();
    this.getAnalytics();
  }

  handleChangedDate(fd: string, dates: Date[], inst: Object): void {
    if (dates.length < 2) return;
    this.beginDate = new CustomDate(dates[0]);
    this.endDate = new CustomDate(dates[1]);
    this.getAnalytics();
  }

  initDatePicker(): void {
    globalThis.$('.datepicker-here').datepicker({
      range: true,
      multipleDatesSeparator: ' - ',
      toggleSelected: false,
      todayButton: new Date(),
      maxDate: new Date(),
      position: "bottom right",
      onSelect: this.handleChangedDate
    });

    globalThis.$('.datepicker-here').data('datepicker').selectDate([
      this.beginDate.date,
      this.endDate.date
    ]);
  }

  addNewCalls(): void {
    this.isLoading = true;
    this.svc$.addNewCalls().subscribe(() => {
      this.isLoading = false;
      this.auth();
    });
  }

  ngOnInit(): void {
    this.addNewCalls();
  }

  updateChart(param: AnalyticsParamDto = null): void {
    if (this.currentParam === null) {
      if (this.analyticsParams.length > 0) {
        this.currentParam = this.analyticsParams[0];
      } else {
        this.chartOptions = null;
        return;
      }
    }

    param = this.currentParam;

    const analytics: AnalyticsByDay[] = this.analyticsByDay;

    this.chartOptions = {
      title: {
        text: ''
      },
      yAxis: {
        title: {
          text: param.caption
        },
        labels: {
          formatter: function () {
            return this.value + (param.after ?? '');
          }
        }
      },
      xAxis: {
        type: 'datetime'
      },
      // series: [{
      //   name: param.caption,
      //   type: 'line',
      //   data: analytics.map
      // }]
      series: analytics.map(item => ({
        type: 'line',
        name: item.name,
        data: item.analytics.map(data => ({
          x: data.date * 1000,
          y: getParamValue(data, param.name)
        }))
      }))
    };

    function getParamValue(data, name) {
      if (param.name === 'saleConversion') {
        return Math.floor(parseFloat((data.analytics[param.name] * 100).toFixed(2)));
      }

      return data.analytics[param.name];
    }
  }

  ngAfterViewInit(): void {}
}
