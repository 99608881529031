import { Analytics, AnalyticsDto } from "./analytics-dto";

export interface AnalyticsByDay {
  id: number;
  name: string;
  analytics: {
    date: number,
    analytics: Analytics
  }[];
}

export class AnalyticsByDayDto {
  id: number;
  name: string;
  analytics: {
    date: number,
    analytics: AnalyticsDto
  }[];

  constructor(options: AnalyticsByDay) {
    this.id = options.id;
    this.name = options.name;
    console.log(options.analytics[0].analytics);
    this.analytics = options.analytics.map(item => ({
      date: item.date,
      analytics: new AnalyticsDto(item.analytics)
    }));
  }
}
