import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { delay, map } from "rxjs/operators";
import { API_BASE_URL } from "../app.config";
import { CustomDate } from "../core/custom-date";
import { AnalyticsByDay, AnalyticsByDayDto } from "../dto/analytics-by-day-dto";
import { Analytics, AnalyticsDto } from "../dto/analytics-dto";
import { AnalyticsParamDto } from "../dto/analytics-param-dto";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private http$: HttpClient) {}

  getAnalyticsByDay(from: CustomDate, to: CustomDate): Observable<AnalyticsByDayDto[]> {
    const params: HttpParams = new HttpParams()
      .set('from', from.toBeginOfDay().toSeconds().toString())
      .set('to', to.toEndOfDay().toSeconds().toString());

    return this.http$.get<AnalyticsByDay[]>(API_BASE_URL + '/get-analytics-by-day', {params})
      .pipe(map(analytics => analytics.map(data => new AnalyticsByDayDto(data))));
  }

  getParams(): Observable<AnalyticsParamDto[]> {
    return of([
      new AnalyticsParamDto({
        name: 'createdLeadsNumber',
        caption: 'Кол-во сделок'
      }),
      new AnalyticsParamDto({
        name: 'salesNumber',
        caption: 'Кол-во продаж'
      }),
      new AnalyticsParamDto({
        name: 'saleConversion',
        caption: 'Конверсия',
        after: '%'
      }),
      new AnalyticsParamDto({
        name: 'callsNumber',
        caption: 'Кол-во звонков'
      }),
      new AnalyticsParamDto({
        name: 'callsDuration',
        caption: 'Длительность звонков'
      }),
      new AnalyticsParamDto({
        name: 'earnings',
        caption: 'Выручка',
        after: '₽'
      }),
      new AnalyticsParamDto({
        name: 'price',
        caption: 'Бюджет',
        after: '₽'
      }),
    ]);
  }

  getAnalytics(from: CustomDate, to: CustomDate): Observable<AnalyticsDto[]> {
    const params: HttpParams = new HttpParams()
      .set('from', from.toBeginOfDay().toSeconds().toString())
      .set('to', to.toEndOfDay().toSeconds().toString());

    return this.http$.get<Analytics[]>(API_BASE_URL + '/get-analytics', {params})
      .pipe(map(analytics => analytics.map(data => new AnalyticsDto(data))));
  }
  
  addNewCalls(): Observable<number> {
    return this.http$.get<number>(API_BASE_URL + '/get-new-calls-from-amocrm');
  }
}
