<div *ngIf="isUserLoggedIn">
  <app-header></app-header>

  <div class="main">
    <div class="container-fluid">
      <table class="table table-hover table-bordered main__table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">№</th>
            <th scope="col">Менеджер</th>
            <th scope="col">Кол-во сделок</th>
            <th scope="col">Кол-во продаж</th>
            <th scope="col">Конверсия</th>
            <th scope="col">Кол-во звонков</th>
            <th scope="col">Длительность звонков</th>
            <th scope="col">Выручка</th>
            <th scope="col">Бюджет</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of analytics; let i = index;">
            <th scope="row">{{i + 1}}</th>
            <td>{{item.managerName}}</td>
            <td>{{item.createdLeadsNumber | currency:'RUB':'':'1.0-0'}}</td>
            <td>{{item.salesNumber | currency:'RUB':'':'1.0-0'}}</td>
            <td>{{item.saleConversion * 100 | number:'1.2-2'}}%</td>
            <td>{{item.callsNumber | currency:'RUB':'':'1.0-0'}}</td>
            <td>{{toNormaTimeView(item.callsDuration)}}</td>
            <td>{{item.earnings | currency:'RUB':'symbol-narrow':'1.0-0'}}</td>
            <td>{{item.price | currency:'RUB':'symbol-narrow':'1.0-0'}}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="main__chart chart">
        <div class="chart__header">
          <p class="chart__title">Сводный график</p>
          <div class="chart__control control">
            <p class="control__name">Параметр:</p>
            <select class="form-control control__select" (change)="onAnalyticsParamChange($event)">
              <option *ngFor="let param of analyticsParams" [value]="param.name">{{param.caption}}</option>
            </select>
          </div>
        </div>
        <highcharts-chart 
          *ngIf="chartOptions; else placeholder"
          [Highcharts]="Highcharts"
          [options]="chartOptions"
          class="chart__content"
        ></highcharts-chart>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>

<div *ngIf="!isUserLoggedIn" class="errorSection">
  <div class="errorSection__btn" (click)="auth()" [disabled]="isLoading">
    <button class="btn btn-lg btn-primary d-flex align-items-center" href="#">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      <span class="">Авторизоваться</span>
    </button>
  </div>
</div>

<app-loading *ngIf="isDataLoading"></app-loading>
