export class CustomDate {
  public date: Date;

  constructor();
  constructor(date: Date);
  constructor(dateString: string);

  constructor(input?: any) {
    if (!input) {
      this.date = new Date();
    }

    if (input instanceof Date) {
      this.date = input;
    }

    if (typeof input === "string") {
      this.date = new Date(input);
    }

    this.resetDate();
  }

  increase(daysNumber: number = 1): void {
    for (let i = 0; i < daysNumber; i++) {
      this.date.setDate(this.date.getDate() + 1);
    }
  }

  private resetDate(): void {
    this.date.setHours(12);
    this.date.setMinutes(0);
    this.date.setSeconds(0);
    this.date.setMilliseconds(0);
  }

  public toBeginOfDay(): this {
    this.date.setHours(0);
    this.date.setMinutes(0);
    this.date.setSeconds(0);
    this.date.setMilliseconds(0);
    return this;
  }
  
  public toEndOfDay(): this {
    this.date.setHours(23);
    this.date.setMinutes(59);
    this.date.setSeconds(59);
    this.date.setMilliseconds(0);
    return this;
  }

  public toSeconds(): number {
    return Math.floor(this.date.getTime() / 1000);
  }

  public toJSON(): string {
    return this.date.toJSON().slice(0, 10);
  }

  public toString(): string {
    return this.toJSON();
  }

  public static equals(firstDate: CustomDate, secondDate: CustomDate): boolean {
    return firstDate.toJSON() === secondDate.toJSON();
  }

  public static clone(customDate: CustomDate): CustomDate {
    return new CustomDate(new Date(customDate.date));
  }
}