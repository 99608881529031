export interface Analytics {
  managerName: string;
  price: number;
  createdLeadsNumber: number;
  salesNumber: number;
  saleConversion: number;
  earnings: number;
  callsNumber: number;
  callsDuration: number;
}

export class AnalyticsDto {
  managerName: string;
  price: number;
  createdLeadsNumber: number;
  salesNumber: number;
  saleConversion: number;
  earnings: number;
  callsNumber: number;
  callsDuration: number;

  constructor(options: Analytics) {
    this.managerName = options.managerName;
    this.price = options.price;
    this.createdLeadsNumber = options.createdLeadsNumber;
    this.salesNumber = options.salesNumber;
    this.saleConversion = options.saleConversion;
    this.earnings = options.earnings;
    this.callsNumber = options.callsNumber;
    this.callsDuration = options.callsDuration;
  }

  getValue(name: string): number {
    if (name === 'saleConversion') return parseFloat((this.saleConversion * 100).toFixed(2));
    else return this[name];
  }
}
