export class CredentialsDto {
  login: string;
  password: string;

  constructor(options: {
    login: string,
    password: string
  }) {
    this.login = options.login;
    this.password = options.password;
  }
}